import { render } from "./Captcha.vue?vue&type=template&id=6a2d9f45"
import script from "./Captcha.ts?vue&type=script&lang=ts"
export * from "./Captcha.ts?vue&type=script&lang=ts"

import "./captcha.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6a2d9f45"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6a2d9f45', script)) {
    api.reload('6a2d9f45', script)
  }
  
  module.hot.accept("./Captcha.vue?vue&type=template&id=6a2d9f45", () => {
    api.rerender('6a2d9f45', render)
  })

}

script.__file = "src/components/Captcha/Captcha.vue"

export default script